<template src="./qr-not-found.html"></template>
<script>
export default {
  name: 'qr-not-found',
  computed: {},
  created() {},
  destroyed() {},
  methods: {
    goTo(url) {
      this.$store.commit('setUrlVm', url);
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
  max-height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  margin-top: 60px;
  width: 276px;
  margin-bottom: 20px;
}

.svg-icon {
  width: 612px;
  margin-bottom: 20px;
}

h1 {
  font-family: lato-regular;
  font-size: 55px;
  font-weight: 700;
  color: #b32656;
  margin-bottom: 15px;
}

p {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 25px;
}

button {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  padding: 30px 120px;
  font-size: 32px;
  border-radius: 48.91px;
  border: none;
  cursor: pointer;
  font-family: lato-regular;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
}

.try-again {
  background-color: #231f20;
  color: white;
}

.cancel {
  background-color: #b32656;
  color: white;
}

.footer {
  position: absolute;
  bottom: -615px;
  background-color: #b32656;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120%;
}

.footer-info-logo {
  width: 40px;
  height: auto;
  margin-right: 15px;
}

.footer-text {
  font-family: 'lato-regular';
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
  color: #ffff;
  padding: 10px;
  border-radius: 5px;
}
</style>
